import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  constructor() { }

  stickyness = false;
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth'})
  }

  track($event) {
    if(window.scrollY > 100){
      this.stickyness = true
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
