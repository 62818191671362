import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { AgbComponent } from './agb/agb.component';
import { ContentComponent } from './content/content.component';

const routes: Routes = [
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: 'agb', component: AgbComponent },
  { path: '', component: ContentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
