import { Directive } from '@angular/core';

@Directive({
  selector: '[appLogo]'
})
export class LogoDirective {

  constructor() { }

}
