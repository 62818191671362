import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {'(window:scroll)': 'track($event)'}
})
export class AppComponent {
  title = 'wsk';
  anio: number = new Date().getFullYear();
  stickyness = false;
  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth'})
  }

  track($event) {
    if(window.scrollY > 100){
      this.stickyness = true
    }
}

}

